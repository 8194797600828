// extracted by mini-css-extract-plugin
export var automotiveBenefitsSection = "N_hn";
export var automotiveCasesSection = "N_hs";
export var automotiveClientQuotesSection = "N_hx";
export var automotiveDevExpertiseSection = "N_hv";
export var automotiveIndustriesSection = "N_hm";
export var automotiveProcessSection = "N_hp";
export var automotiveRequirementsSection = "N_hr";
export var automotiveServicesSection = "N_hl";
export var automotiveSkilledSection = "N_hw";
export var automotiveTechStackSection = "N_hq";
export var automotiveWhyHireSection = "N_ht";